import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"

export default () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
    navigate("/not-found")
  }, [])

  if (!isMount) {
    return
  }

  return <div>   </div>
}